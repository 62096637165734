import React, { useState } from 'react';
import 'styled-components/macro';
import {
  PricingContainer,
  PricingCard,
  PlanHeader,
  Price,
  FeatureList,
  FeatureItem,
  ActionButton,
  PricingBlockSwitcherInner,
  PricingBlockSwitcherLabel,
  PricingBlockSwitcherTab,
  AnnualTab,
  PricingBlockSwitcherDiscount,
  PriceSave,
  PriceHolder,
} from './offers.styles';
import ModalContainer from 'components/molecules/ModalContainer';
import DateModal from 'components/organisms/skilled-dev/DateModal';
import checkMark from 'assets/icons/price-list-check.svg';

const FeatureIcon = () => <img src={checkMark} alt="checkmark" css="width: 1.5rem; margin-right: 0.5rem;" />;

const PricingBlockSwitcher = ({ activeTab, setActiveTab }) => {
  // Calculate the position for the label slider based on active tab
  const getTranslateX = () => {
    switch (activeTab) {
      case 'annual':
        return 0;
      case 'monthly':
        return 165;
      default:
        return 0;
    }
  };
  const getElWidth = () => {
    switch (activeTab) {
      case 'annual':
        return 172;
      case 'monthly':
        return 136;
      default:
        return 136;
    }
  };
  return (
    <PricingBlockSwitcherInner>
      <PricingBlockSwitcherLabel translateX={getTranslateX()} width={getElWidth()} />
      <AnnualTab id="annual" className={activeTab === 'annual' ? 'active' : ''} onClick={() => setActiveTab('annual')}>
        <span data-text="Annual">Annual</span>
        <PricingBlockSwitcherDiscount id="discount">
          <span>Save 20%</span>
        </PricingBlockSwitcherDiscount>
      </AnnualTab>
      <PricingBlockSwitcherTab
        css={`
          margin-left: 10px;
          margin-right: 10px;
        `}
        id="monthly"
        className={activeTab === 'monthly' ? 'active' : ''}
        onClick={() => setActiveTab('monthly')}>
        <span data-text="Monthly">Monthly</span>
      </PricingBlockSwitcherTab>
    </PricingBlockSwitcherInner>
  );
};
const Offers = () => {
  const [activeTab, setActiveTab] = useState('annual');
  const priceStandred = 120;
  const pricePremium = 480;
  return (
    <>
      <PricingBlockSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
      <PricingContainer>
        <PricingCard>
          <PlanHeader>Standard</PlanHeader>
          <PriceHolder>
            <Price>£{activeTab === 'monthly' ? priceStandred : priceStandred - 0.2 * priceStandred}</Price>/mo
            {activeTab !== 'monthly' && (
              <PriceSave>Save {priceStandred * 12 - (priceStandred - priceStandred * 0.2) * 12}$</PriceSave>
            )}
          </PriceHolder>
          <ModalContainer
            xl
            btnComponent={({ onClick }) => <ActionButton onClick={onClick}>Get Standard</ActionButton>}
            content={({ onClose }) => <DateModal onClose={onClose} type={`${activeTab} Standred`} />}
          />
          <FeatureList>
            <FeatureItem available>
              <FeatureIcon />
              Live dashboard
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon /> History data
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Weekly Reports
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Support
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Unlimited Users
            </FeatureItem>
          </FeatureList>
        </PricingCard>

        <PricingCard>
          <PlanHeader>Premium</PlanHeader>
          <PriceHolder>
            <Price>£{activeTab === 'monthly' ? pricePremium : pricePremium - 0.2 * pricePremium}</Price>/mo
            {activeTab !== 'monthly' && (
              <PriceSave>Save {pricePremium * 12 - (pricePremium - 0.2 * pricePremium) * 12}$</PriceSave>
            )}
          </PriceHolder>
          <ModalContainer
            xl
            btnComponent={({ onClick }) => <ActionButton onClick={onClick}>Get Premium</ActionButton>}
            content={({ onClose }) => <DateModal onClose={onClose} type={`${activeTab} Premium`} />}
          />

          <FeatureList>
            <FeatureItem available>
              <FeatureIcon />
              Live dashboard
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              History data
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Weekly Reports
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Support
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Unlimited Users
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Monthly Reports
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Anual Reports
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Custom Manual Fields
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Export Option
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Stoppages Reasons
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Alerts
            </FeatureItem>
          </FeatureList>
        </PricingCard>

        <PricingCard>
          <PlanHeader>Custom Software</PlanHeader>
          <PriceHolder>
            <Price>n/a</Price>
          </PriceHolder>
          <ModalContainer
            xl
            btnComponent={({ onClick }) => <ActionButton onClick={onClick}>Contact Sales</ActionButton>}
            content={({ onClose }) => <DateModal onClose={onClose} type={'Custom Software'} />}
          />
          <FeatureList>
            <FeatureItem available>
              <FeatureIcon />
              Live dashboard
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              History data
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Weekly Reports
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Support
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Unlimited Users
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Monthly Reports
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Anual Reports
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Custom Manual Fields
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Export Option
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Stoppages Reasons
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Alerts
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Periodic Meetings
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Integrations
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Automatic Stoppages
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Custom Software
            </FeatureItem>
            <FeatureItem available>
              <FeatureIcon />
              Analogic Information
            </FeatureItem>
          </FeatureList>
        </PricingCard>
      </PricingContainer>
    </>
  );
};

export default Offers;
